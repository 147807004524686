/**
 * Note: For SQ spacific pages: (meta)
 * For multitanent pages:(multiTenentMeta)
 *
 **/

export const meta: any = {
  solar: {
    title: "Solar services provider - Solar Quote",
    description: `Save on building's running costs with solar panels. Best solar brands under one website. Free estimate. Compare multiple quotes.`,
    keywords:
      "solar energy, sustainable energy, green energy, clean energy, solar panel installation, solar installers near me, solar solutions, solar rooftop installation, solar pv installer",
  },
  battery: {
    title: "Solar power storage batteries - Solar Quote",
    description: `Find a battery storage that fits your requirements. Pay for exactly what you use visit solarquote.com to know more`,
    keywords:
      "battery storage, battery storage system, energy storage, battery energy storage system, solar energy storage, solar storage, renewable energy storage, off grid energy storage systems",
  },
  whySolar: {
    title: "Why shift to solar energy - Solar Quote",
    description:
      "The sun is nearly limitless and can be accessed anywhere on earth. Make the best use of this free source of power, generate green energy to power your home. Get solar.",
    keywords:
      "green energy solar, advantages of solar energy, benefits of solar energy, solar tax credit, federal solar tax credit, solar renewable energy",
  },
  company: {
    title: "Solar Quote company",
    description:
      "Go solar online, reduce monthly bills, discover how Solar Quote can help you in your solar journey.",
    keywords:
      "solarquote, solar installation company, solar energy solutions, solar panel installation company, solar power solutions",
  },
  blog: {
    title: "Solar energy blogs",
    description: `Power your home and business with clean, renewable energy. Take control of your energy with Solar Quote, leading solar panel and battery installation company.`,
    keywords:
      "uses of solar energy at home, most efficient solar panels, benefit of solar panels, working of solar energy, Green solar energy, Most efficient solar panel",
  },
  support: {
    title: "Customer support service - Solar Quote",
    description: `Speak to the experts. Fill in the contact form and we'll set up a time to discuss all of your questions regarding solar.`,
    keywords: "solar experts, solar energy experts, solar professionals",
  },
  resources: {
    title: "Best solar energy service providers - Solar Quote",
    description: `Going solar has never been so simple. Get your instant and accurate Solar Quote today! Solar Quote is the best solar panel installation company in USA.`,
    keywords:
      "electricity from solar energy, solar battery storage benefits, Low-carbon technology, grid-connected PV systems, Renewable energy",
  },
  landingCal: {
    title: "Solar estimate calculator | Solarquote",
    description: `Calculate or estimate your solar panel requirements with a unique AI design tool from Solar Quote. Going solar the way you want has never been easie`,
    keywords:
      "solar calculator, solar panel calculator, solar rooftop calculator, solar quote, solar panel cost calculator, solar panels kwh calculator, solar energy calculator, solar panel wattage calculator, solar calculator online, solar panel power calculation, solar panel requirement calculator, solar panel calculator for home, solar power kwh calculator, solar estimation, solar estimate calculator",
  },
};

const MultiTenentMeta = (
  orgName: string,
  isAmbassadorProgram: boolean
): {
  [key: string]: { title: string; description: string; keywords: string };
} => ({
  affiliate: {
    title: `${orgName} ${isAmbassadorProgram ? '' : 'Ambassador Program'}`,
    description: `Get rewards for each referral to ${orgName} you make. Help encourage others to go solar and earn cash rewards, trips and once-in-a-lifetime experiences!`,
    keywords:
      "solar calculator, solar panel calculator, solar rooftop calculator, solar quote, solar panel cost calculator, solar panels kwh calculator, solar energy calculator, solar panel wattage calculator, solar calculator online, solar panel power calculation, solar panel requirement calculator, solar panel calculator for home, solar power kwh calculator, solar estimation, solar estimate calculator",
  },

  index: {
    title: "Buy Solar Directly Online | We Make Solar Simple",
    description: `With ${orgName}'s AI-Powered platform, you get an instant solar design and quote in minutes. No hidden fees, Unbeatable Prices. Go Solar Now!`,
    keywords:
      "solar calculator, solar panel calculator, solar rooftop calculator, solar quote, solar panel cost calculator, solar panels kwh calculator, solar energy calculator, solar panel wattage calculator, solar calculator online, solar panel power calculation, solar panel requirement calculator, solar panel calculator for home, solar power kwh calculator, solar estimation, solar estimate calculator",
  },
  thankYou: {
    title: `Thank You | ${orgName}`,
    description: ``,
    keywords:
    "",
  },
});

export function getMultiTenentMeta(orgName: string, page: string, isAmbassadorProgram:boolean=false) {
  return MultiTenentMeta(orgName || "", isAmbassadorProgram)[page];
}
