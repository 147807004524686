
  export const OrgProgramTypeAffiliateRoutes = {
    index: "/ambassador",
    login: "/ambassador/login",
    forgotPassword: "/ambassador/forgotPassword",
    setPassword: "/ambassador/resetPassword",
  };
  
  export const AmbassadorProgramTypeAffiliateRoutes = {
    index: "/",
    login: "/login",
    forgotPassword: "/forgotPassword",
    setPassword: "/resetPassword",
  };
  