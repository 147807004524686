import React, { useContext } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { UserContext } from "../../components/UserContext";
import { toast } from "react-toastify";
import { useRouter } from "next/router";
import Link from "next/link";
import { useOrgTheme } from "../../context/ThemeContext";

interface Props {
  openRegisterModal?: () => void;
  closeLogin: () => void;
}
interface LoginPayload {
  username: string;
  password: string;
  hostname_id?: number;
  organization_id?: number;
  is_preview?: boolean;
}
const toastOptions = {
  autoClose:3000
}

const ReferralLoginModal = (props: Props) => {
  const { setloader } = useContext(UserContext);
  const router = useRouter();
  const { getOrganizationId, getHostnameID,  isPreviewDomain, getAffiliateRoutes} = useOrgTheme();
  // getOrganizationId()
  const AffiliateRoutes= getAffiliateRoutes();
  const onCloseAll = () => {
    props.closeLogin();
  };
  const formID= 'referral-login-form';

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
      organization_id: getOrganizationId(),
      hostname_id: getHostnameID(),
      is_preview: isPreviewDomain()
    },
    validationSchema: yup.object({
      username: yup.string().required("Username is required"),
      password: yup.string().required("Password is required"),
    }),
    onSubmit: (formData: any) => {
      handleReferralLogin(formData);
    },
  });
  const handleReferralLogin = async (formData: LoginPayload) => {
    setloader(true);
    try {
      const res = await fetch(process.env.API_URL + "referral/login", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const result: any = await res.json();
      if (res.ok) {
        if (result) {
          localStorage.setItem("token", result.token);
          localStorage.removeItem("firstLogin");
          router.push({ pathname: `/${formData.username}/profile` });
          toast.success("LoggedIn Successfully",toastOptions);
        }
        return;
      }

      if (result?.errors?.length) {
        console.log(result);
        toast.error(result.errors[0],toastOptions);
      }
      setloader(false);
    } catch (e) {
      setloader(false);
      return null;
    }
  };

  return (
    <div className="">
      <div className="lead relative flex-col-reverse md:flex-row">
        <div className="pt-16 p-4 md:p-8 md:py-14 md:px-12">
          {/* <div className="EstimateDetailModal__Overlay form-control-overlay otp-dialog"> */}

          <div className="relative">
            <svg
              onClick={() => {
                onCloseAll();
              }}
              className="w-8 h-8 text-bodyColor float-right absolute -top-12  -right-2 md:-right-10 mr-0.5 cursor-pointer"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
            <h2 className="text-left mb-3 text-2xl font-primaryMedium text-titleColor">
              Sign in to your account
              {/* Already made a purchase? Check your status here... */}
            </h2>

            {/* <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10"> */}
            <form id={formID} onSubmit={formik.handleSubmit} action="#" method="POST">
              <div className="grid grid-cols-1 gap-4">
                <div className="relative">
                  <p className="mb-1 text-sm xxs:text-base text-titleColor">
                    Username <span className="text-primary"> *</span>
                    {formik.touched.username && formik.errors.username ? (
                      <span className="text-red-500 text-xs w-full ml-2">
                        {formik.errors.username}
                      </span>
                    ) : null}
                  </p>
                  <input
                    placeholder="username"
                    {...formik.getFieldProps("username")}
                    id={`${formID}-username`}
                    name="username"
                    className="text-sm md:text-base focus:ring-2 focus:ring-titleColor focus:ring-offset-white  focus:outline-none p-2.5 border border-gray-300 rounded w-full placeholder-current text-bodyColor"
                  />
                </div>
                <div className="relative">
                  <p className="mb-1 text-sm xxs:text-base text-titleColor">
                    Password <span className="text-primary"> *</span>
                    {formik.touched.password && formik.errors.password ? (
                      <span className="text-red-500 text-xs w-full ml-2">
                        {formik.errors.password}
                      </span>
                    ) : null}
                  </p>
                  <input
                    placeholder="Password"
                    {...formik.getFieldProps("password")}
                    id={`${formID}-password`}
                    name="password"
                    type="password"
                    className="text-sm md:text-base focus:ring-2 focus:ring-titleColor focus:ring-offset-white  focus:outline-none p-2.5 border border-gray-300 rounded w-full placeholder-current text-bodyColor"
                  />
                </div>
              </div>
              <Link href={AffiliateRoutes.forgotPassword}>
                <a className="mt-2 font-interRegular text-primary text-sm underline flex self-end cursor-pointer">
                  Forgot password
                </a>
              </Link>

              <button
                disabled={
                  (formik.touched.username && formik.errors.username) ||
                  (formik.touched.password && formik.errors.password)
                    ? true
                    : false
                }
                id={`${formID}-submit-btn`}
                type="submit"
                className="disabled:opacity-50 block p-3 my-4 mt-8 w-full hover:shadow-lg bg-primary text-white text-sm 2xl:text-lg xxs:text-lg font-primaryRegular uppercase text-center rounded"
              >
                Login
              </button>
            </form>
            {/* <div className="my-6">
                            <div className="relative">
                                <div className="absolute inset-0 flex items-center">
                                    <div className="w-full border-t border-gray-300"></div>
                                </div>
                                <div className="relative flex justify-center text-sm">
                                    <span className="bg-white px-2 text-gray-500">Or continue with</span>
                                </div>
                            </div>

                        </div>
                        <button onClick={() => { onClickNow() }} type="submit" className="disabled:opacity-10 block p-3 w-full hover:shadow-lg bg-secondary text-white text-sm 2xl:text-lg xxs:text-lg font-primaryRegular uppercase text-center rounded">Create New Account</button> */}
            {/* </div>
          </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralLoginModal;
