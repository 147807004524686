import React, { ReactNode, useEffect, useState } from "react";
// import themeData from "../config/themeData.json";
import { LAYOUT_DATA } from "../constants/defaultSetting.constant";
import { BannerLayout, OrganisationTheme } from "../interfaces/Theme";
import ThemeContext from "./ThemeContext";
import { BANNER_LAYOUT } from "../components/Home/Client/Banner";
import { useOrgThemeByDomain } from "../hooks/API/useOrgThemeByDomain";
import { usePersistedState } from "../hooks/Common/usePersistedState";
import { AffiliateContent } from "../pages/ambassador";
import { isColorDark, isColorLight } from "../utils/color.utils";
import decrypt from "../utils/decrypt.utils";
import { loadScripts } from "../utils/theme.utills";
import { AmbassadorProgramTypeAffiliateRoutes, OrgProgramTypeAffiliateRoutes } from "../utils/ambassador";

const clientAccessableRoutes = [
  "estimate",
  "instant-estimate",
  "instant-estimate-result",
  // "account",
  "ambassador",
  "self-site-survey",
  "proposal",
  "referral",
  "profile",
  "auth",
  // "thank-you",
];

const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const initialStatusState = { isSuntuity: false, isClientOrg: false }; // For now once isSuntuity is dynamic no need for this state
  const [domainStatus, setDomainStatus] =
    useState<typeof initialStatusState>(initialStatusState);
  const [currentTheme, setCurrentTheme] = useState<OrganisationTheme | null>(
    null
  );
  const [isThemeLoaded, setIsThemeLoaded] = useState(false);
  const [hostname, sethostname] = usePersistedState(null, "host");

  if (typeof window !== "undefined") {
    var { data, error } = useOrgThemeByDomain();
  }

  useEffect(() => {
    if (data?.orgId) {
      setupTheme(data);
      sethostname({
        id: data?.hostNameId,
        orgId: data?.orgId,
        preview: !!data?.is_preview,
      });
      setIsThemeLoaded(true);
    }
  }, [data?.orgId]);


  const getTheme = () => {
    return currentTheme || ({} as OrganisationTheme);
  };

  const setTheme = (cssVariables: OrganisationTheme["cssVariables"]) => {
    for (const key in cssVariables) {
      const value = cssVariables[key as keyof typeof cssVariables];
      const variableName = `--${key}`;
      document.documentElement.style.setProperty(variableName, value);
    }
  };

  const getLogoUrl = () => {
    const theme = getTheme();
    return theme.logo;
  };

  const setIframeTheme = (
    iframe: HTMLIFrameElement,
    isIncludeInStep = false
  ) => {
    const theme = getTheme();
    const { cssVariables, dataForTool } = theme;
    const data = {
      id: "css",
      colors: cssVariables,
    };
    const toolData = { ...dataForTool };
    if (!isIncludeInStep) {
      toolData.nextSteps = [];
      toolData.seeRecommendButtonText = "";
    }
    const ifarmeData = {
      id: "BIND_DATA",
      data: { ...toolData },
    };
    for (let i = 0; i < 8; i++) {
      setTimeout(() => {
        cssVariables && iframe?.contentWindow?.postMessage(data, "*");
        dataForTool && iframe?.contentWindow?.postMessage(ifarmeData, "*");
      }, i * 1000);
    }
  };

  const getOrganizationId = () => {
    const { orgId } = getTheme();
    return orgId || hostname?.orgId;
  };

  const isServiceAvailableAt = (stateCode: string) => {
    const theme = getTheme();
    const { availableStates = [] } = theme;
    for (let i = 0; i < availableStates.length; i++) {
      const element = availableStates[i];
      if (element === stateCode) return true;
    }
    return false;
  };

  const getStripePulishableKey = () => {
    const theme = getTheme();
    const { stripPublishableKey } = theme;
    return decrypt(stripPublishableKey);
  };

  const checkDomainStatus = (theme: OrganisationTheme) => {
    const { orgSlug } = theme;
    const isClientOrg = !isSolarQuote(orgSlug);
    const isSuntuity = orgSlug === "suntuity";

    const domains = {
      isClientOrg,
      isSuntuity,
    };
    setDomainStatus((prev) => ({ ...prev, ...domains }));
  };

  const getOrgName = () => {
    const { orgName } = getTheme();
    return orgName;
  };

  const getLeaseConfig = () => {
    const { lightReachConfig, sunnovaConfig }: any = getTheme();
    if (lightReachConfig && typeof lightReachConfig === "object") {
      lightReachConfig.isCustomWithCardFinanceLayout =
        isCustomWithCardFinanceLayout;
    }
    return {
      lightReachConfig,
      sunnovaConfig,
    };
  };

  const colorInversion = (colors: any) => {
    const { buttonBackground, buttonText } = colors;
    if (!(buttonBackground && buttonText)) {
      return;
    }
    if (isColorDark(buttonBackground) && isColorDark(buttonText)) {
      colors.buttonText = "#fff";
    } else if (isColorLight(buttonBackground) && isColorLight(buttonText)) {
      colors.buttonText = "#000";
    }
  };

  const setupTheme = (theme: any) => {
    if (theme) {
      const { scripts = [] } = theme;
      if (!!scripts?.length) {
        setTimeout(()=>{
          loadScripts(scripts);
        },100)
      }
      colorInversion(theme.cssVariables);
      checkDomainStatus(theme);
      setCurrentTheme(theme);
      setTheme(theme?.cssVariables);
    }
  };

  function getToolConfigurations() {
    const { toolPublicKey, toolUrl } = getTheme();
    return {
      publicKey: toolPublicKey || (process.env.PUBLIC_KEY as string),
      url: toolUrl || (process.env.TOOL_URL as string),
    };
  }

  function getOrgSettings() {
    const settings: any = currentTheme?.settings || {};
    // const isDifferentEnergies = getOrgSlug() === "different-energies";
    // const show_finance_options = !isDifferentEnergies;
    // settings.show_finance_options = show_finance_options;
    return settings as OrganisationTheme["settings"];
  }

  function hasRouteAccess(pathname = window.location.pathname) {
    if(isAmbassadorProgram && Object.values(AmbassadorProgramTypeAffiliateRoutes).includes(pathname)){
      return true;
    }
    const routeName = pathname.includes("/profile")
      ? "profile"
      : pathname.split("/")[1];
    if (routeName === "thank-you" && !isContractSignEnabled) {
      return true;
    }
    if (routeName === "account" && isContractSignEnabled) {
      return true;
    }
    console.log(routeName);
    if (pathname === "/" || !domainStatus.isClientOrg) {
      return true;
    }
    const { show_affialiate } = getOrgSettings();
    if (routeName === "ambassador" && !show_affialiate) {
      return false;
    }
    return clientAccessableRoutes.includes(routeName);
  }

  function getContent() {
    const { pageContent } = getTheme();
    return pageContent || {};
  }
  function getTrustedPartners() {
    const { trustedPartners } = getTheme();
    return trustedPartners || [];
  }

  function getHomePageContent() {
    const { homepageContent } = getContent();
    return homepageContent || {};
  }

  function getHomeLayoutContent(
    content?: OrganisationTheme["pageContent"]["homepageContent"]
  ) {
    const homepageContent = content || getHomePageContent();
    const { bannerLayout = BANNER_LAYOUT.LAYOUT_3 } = homepageContent;
    const layoutContent = buildBannerData(
      bannerLayout,
      homepageContent?.[bannerLayout as BANNER_LAYOUT]
    );
    return { ...layoutContent };
  }

  function buildBannerData(
    layout: BANNER_LAYOUT,
    layoutContent: BannerLayout | undefined
  ): BannerLayout {
    const defaultLayoutContent = LAYOUT_DATA[
      layout as BANNER_LAYOUT
    ] as BannerLayout;
    if (!layoutContent) {
      return { ...defaultLayoutContent };
    }
    return {
      type: layout,
      formBtnLabel:
        layoutContent?.formBtnLabel || defaultLayoutContent.formBtnLabel,
      formDescription:
        layoutContent?.formDescription || defaultLayoutContent?.formDescription,
      formHeading:
        layoutContent?.formHeading || defaultLayoutContent?.formHeading,
      backgroundImage:
        layoutContent?.backgroundImage || defaultLayoutContent?.backgroundImage,
      content: layoutContent?.content || defaultLayoutContent.content,
      formDisclaimer: layoutContent?.formDisclaimer || "",
    };
  }

  function getHomeDynamicSections() {
    const { htmlContent = {} } = getTheme();
    return htmlContent;
  }

  function isSolarQuote(orgSlug: string) {
    return orgSlug === "solarquote";
  }

  function getOrgFavicon() {
    const { favIcon } = getTheme();
    return favIcon || "";
  }

  function getOrgSlug() {
    const { orgSlug } = getTheme();
    return orgSlug;
  }
  function getHostnameID() {
    const { hostNameId } = getTheme();
    return hostNameId || hostname?.id;
  }

  function isPreviewDomain() {
    const { is_preview } = getTheme();
    return is_preview || hostname?.preview;
  }

  function setAffiliatePageContent(content: AffiliateContent) {
    let theme = currentTheme || ({} as OrganisationTheme);
    theme = {
      ...theme,
      ...content,
    };
    setCurrentTheme(theme);
  }

  function getAffiliateRoutes() {
    if(isAmbassadorProgram){
      return AmbassadorProgramTypeAffiliateRoutes;
    }
    return OrgProgramTypeAffiliateRoutes;
  }

  //  function setReferralPageContent(content: AffiliateContent) {
  //    let theme = currentTheme || ({} as OrganisationTheme);
  //    theme = {
  //      ...theme,
  //      ...content,
  //    };
  //    setCurrentTheme(theme);
  //  }
  const finance_method = currentTheme?.settings?.finance_method;
  const isSinglePageLayout =
    currentTheme?.settings?.customer_site_layout?.desktop === "single_page";
  const singleFinanceLayout = finance_method === 0;
  const stepFinanceLayout = finance_method === 1;
  const isCustomWithCardFinanceLayout = finance_method === 2;
  // const isSunSource = getOrgSlug() === "sun-source";
  const isDifferentEnergies = getOrgSlug() === "different-energies";
  const isContractSignEnabled = !!currentTheme?.settings?.contract_sign_enabled;
  const {isAmbassadorProgram} =getTheme();
  // const isContractSignEnabled = getOrgSlug() !== "sun-source"
  useEffect(() => {
    localStorage.setItem("isContractSignEnabled", `${isContractSignEnabled}`);
  }, [isContractSignEnabled]);
  return (
    <ThemeContext.Provider
      value={{
        getLogoUrl,
        setIframeTheme,
        getTheme,
        getOrganizationId,
        isServiceAvailableAt,
        getStripePulishableKey,
        getOrgName,
        isThemeLoaded,
        ...domainStatus,
        // setupTheme,
        getOrgSettings,
        getToolConfigurations,
        hasRouteAccess,
        getContent,
        getTrustedPartners,
        getHomeDynamicSections,
        getHomePageContent,
        getOrgFavicon,
        getHomeLayoutContent,
        getLeaseConfig,
        error,
        getOrgSlug,
        getHostnameID,
        setTheme,
        sethostname,
        isPreviewDomain,
        setAffiliatePageContent,
        isSinglePageLayout,
        singleFinanceLayout,
        stepFinanceLayout,
        isCustomWithCardFinanceLayout,
        isContractSignEnabled,
        isDifferentEnergies,
        isAmbassadorProgram,
        getAffiliateRoutes
        // isSunSource
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
