import Link from "next/link";
import React, { useContext, useState, useEffect, useRef, useMemo } from "react";
import { UserContext } from "../UserContext";
import router, { useRouter } from "next/router";
import Modal from "react-modal";
import OtpModal from "../OtpModal";
import PurchasesForContactModal from "../purchasesForContactModal";
import CurrentPruchaseConfirmationModal from "../CurrentPruchaseConfirmationModal";
import { useOrgTheme } from "../../context/ThemeContext";
import {
  LEAD_ACTION_TYPE,
  useLeadContext,
  useLeadDispatchContext,
} from "../../context/LeadContext";
import { getLeadData, setLeadData } from "../../utils/common.function.utils";
import { LeadOnly } from "../../interfaces/lead.interface";
import { LEAD_STATUS } from "../../enums/leadStatus.enum";
import TalkToExpert from "../Common/TalkToExpert";
// import { isMobile } from "../DeviceType";
import DesktopHeaderClient from "./Client";
import SQHeader from "./SQHeader";
import MobileHeader from "./MobileHeader";

interface Props {
  landingPages: any[];
  hideCurrentPurchaseAndTrackYourPurchaseButtons: boolean;
}

// const defaultLogoUrl = "/images/logo.png";
const defaultLogoUrl = "/images/easy-quote-logo.png";
// const defaultLogoUrl = null;

const Header = (props: Props) => {
  const {
    getLogoUrl,
    isClientOrg,
    isSuntuity,
    getTheme,
    getOrgSettings,
    getOrgSlug,
    isThemeLoaded,
    isAmbassadorProgram
  } = useOrgTheme();
  const isSolarQuote = getOrgSlug() === "solarquote";
  const { show_affialiate, external_links, customer_site_layout } =
    getOrgSettings();
  const isSinglePageLayout = customer_site_layout?.desktop === "single_page"; //TO-DO
  const { contactNumbers = [] } = getTheme();
  const contactNumber: any = contactNumbers[0];
  const logoUrl = getLogoUrl() || defaultLogoUrl;
  const leadDisapatcher = useLeadDispatchContext();
  const leadData = useLeadContext();

  const { setloader, isStateNewJersey, setUserData } = useContext(UserContext);
  const route = useRouter();
  const [openOtpModal, setOpenOtpModal] = useState(false);
  const [openMobileView, setMobileView] = useState(false);
  const [openMobileMenuView, setMobileMenuView] = useState(0);
  const [landingRoutes, setLandingRoute] = useState<string[]>([]);
  const [landingPagesLocation, setLandingPages] = useState<any>({});
  const [showGoToCurrentPurchaseButton, setShowGoToCurrentPurchaseButton] =
    useState<boolean>(false);
  const [allPurchasesForContact, setAllPurchasesForContact] = useState<any>([]);
  const [openPurchasesForContactModal, setOpenPurchasesForContactModal] =
    useState(false);
  const [
    openCurrentPurchaseConfirmationModal,
    setOpenCurrentPurchaseConfirmationModal,
  ] = useState<boolean>(false);
  const solidHeaderRef = useRef<any>();
  const [isSulg, setIsSlug] = useState<boolean>(false);

  useEffect(() => {
    if (router.query.trackpurchase === "true") {
      setOpenOtpModal(true);
    }
  }, []);

  useEffect(() => {
    const landingRoute = props?.landingPages.map((page: any) => {
      return page.slug;
    });
    setLandingRoute(landingRoute);
  }, []);

  useEffect(() => {
    let landingPagesLocation: any = {};
    props?.landingPages.map((landingpage: any) => {
      landingPagesLocation[landingpage.slug] =
        landingpage?.layout?.bannerLayout;
    });
    setLandingPages(landingPagesLocation);
  }, []);

  useEffect(() => {
    if (leadData && Object.keys(leadData).length) {
      setShowGoToCurrentPurchaseButton(true);
    }
  }, [leadData]);

  useEffect(() => {
    if (allPurchasesForContact.length) {
      closeOtpModalMethod();
      openPurchasesForContactModalMethod();
    }
  }, [allPurchasesForContact]);

  useEffect(() => {
    if (typeof window !== "undefined" && window) {
      window.addEventListener("scroll", handleScroll);
    }
  }, []);

  const handleScroll = function () {
    let changeClass = false;
    var scrollTop =
      window.pageYOffset ||
      (document.documentElement || document.body.parentNode || document.body)
        .scrollTop;

    changeClass = scrollTop > 100;

    if (solidHeaderRef?.current) {
      setIsSlug(changeClass);
    }
  };

  const setMenutoggle = (val: number) => {
    if (val == openMobileMenuView) {
      setMobileMenuView(0);
    } else {
      setMobileMenuView(val);
    }
  };

  function closeOtpModalMethod() {
    setOpenOtpModal(false);
  }

  function signIn() {
    setOpenOtpModal(true);
  }

  async function navigate(responseData: any) {
    // await setUserData(responseData);
    // localStorage.setItem("leadData", JSON.stringify(responseData));
    // setloader(true);
    // closeOtpModalMethod();
    // route.push({ pathname: "/account" });
    setUserData(responseData);
    leadDisapatcher({
      type: LEAD_ACTION_TYPE.UPDATE_LEAD,
      payload: responseData,
    });
    closeOtpModalMethod();
    if (isCurrentPurchaseExit(responseData.id)) {
      setloader(false);
      setOpenCurrentPurchaseConfirmationModal(true);
    } else {
      navigateToNewPurchase(responseData);
    }
  }

  function isCurrentPurchaseExit(currentId: string) {
    const leadData = getLeadData();
    if (!leadData) return false;

    if (leadData.instantEstimate) return true;
    return leadData.id !== currentId ? true : false;
  }

  async function navigateToNewPurchase(response?: LeadOnly) {
    if (openCurrentPurchaseConfirmationModal) {
      setOpenCurrentPurchaseConfirmationModal(false);
    }
    const lead = response || leadData;
    setLeadData(lead);
    setloader(true);

    const pathToNavigate = getPathToNavigate(lead);
    route.prefetch(pathToNavigate);
    route.push({ pathname: pathToNavigate });
  }

  function getPathToNavigate(lead: LeadOnly) {
    if (lead.status === LEAD_STATUS.CONVERTED) {
      return "/account";
    }
    return "/estimate";
  }

  function navigateToCurrentPurchase() {
    setOpenCurrentPurchaseConfirmationModal(false);
    goToCurrentPurchase();
  }
  // =====================================
  function goToCurrentPurchase() {
    const userInfo: any = getLeadData();
    leadDisapatcher({ type: LEAD_ACTION_TYPE.UPDATE_LEAD, payload: userInfo });
    // setUserData(userInfo);
    setloader(true);
    if (!userInfo.instantEstimate) {
      if (userInfo.status != LEAD_STATUS.CONVERTED) {
        route.prefetch("/estimate");
        route.push({ pathname: "/estimate" });
      } else {
        route.push({ pathname: "/account" });
      }
    } else {
      if (userInfo.hasOwnProperty("estimate")) {
        route.push({ pathname: "/instant-estimate-result" });
      } else {
        route.push({ pathname: "/instant-estimate" });
      }
    }
  }

  function openPurchasesForContactModalMethod() {
    setOpenPurchasesForContactModal(true);
  }

  function closePurchasesForContactModalMethod() {
    setOpenPurchasesForContactModal(false);
  }

  function handleLogoClick() {
    leadDisapatcher({ type: LEAD_ACTION_TYPE.RESER_LEAD });
  }

  const isEstimatePage = useMemo(() => {
    return route.pathname.includes("/estimate");
  }, [route.pathname]);

  const canShowRegulerHeader = (pathname: string) => {
    const pages = [
      "/account",
      "/self-site-survey",
      "/instant-estimate",
      "/instant-estimate-result",
      "/shopnow",
      "/ai",
      "/privacy",
      "/thank-you"
    ];
    const excludedKeywords = ["profile", "referral", "ambassador"];
    return (
      !isEstimatePage &&
      pages.indexOf(pathname) === -1 &&
      !excludedKeywords.some((keyword) => pathname.includes(keyword)) &&
      landingRoutes.indexOf(
        route?.query?.slug ? route?.query?.slug.toString() : ""
      ) === -1
    );
  };
  const getHeader = () => {
    if (canShowRegulerHeader(route.pathname)) {
      return (
        <>
          {isSolarQuote && (
            <SQHeader
              isStateNewJersey={isStateNewJersey}
              handleLogoClick={handleLogoClick}
              logoUrl={logoUrl}
              setMobileView={setMobileView}
              openMobileView={openMobileView}
              route={route}
              show_affialiate={show_affialiate}
              external_links={external_links}
              showGoToCurrentPurchaseButton={showGoToCurrentPurchaseButton}
              goToCurrentPurchase={goToCurrentPurchase}
              contactNumber={contactNumber}
              signIn={signIn}
              hideCurrentPurchaseAndTrackYourPurchaseButtons={
                props?.hideCurrentPurchaseAndTrackYourPurchaseButtons
              }
            />
          )}
          {isClientOrg && (
            <DesktopHeaderClient
              isSuntuity={isSuntuity}
              handleLogoClick={handleLogoClick}
              isStateNewJersey={isStateNewJersey}
              logoUrl={logoUrl}
              setMobileView={setMobileView}
              openMobileView={openMobileView}
              signIn={signIn}
              hideCurrentPurchaseAndTrackYourPurchaseButtons={
                props?.hideCurrentPurchaseAndTrackYourPurchaseButtons
              }
            />
          )}

          <MobileHeader
            openMobileView={openMobileView}
            handleLogoClick={handleLogoClick}
            isStateNewJersey={isStateNewJersey}
            logoUrl={logoUrl}
            setMobileView={setMobileView}
            setMenutoggle={setMenutoggle}
            openMobileMenuView={openMobileMenuView}
            showGoToCurrentPurchaseButton={showGoToCurrentPurchaseButton}
            goToCurrentPurchase={goToCurrentPurchase}
          />
        </>
      );
    }
    return <></>;
  };

  if (!isThemeLoaded ||isAmbassadorProgram  && !route.pathname.includes("profile")) {
    return <></>;
  }
  return (
    <>
      {canShowRegulerHeader(route.pathname) ? (
        getHeader()
      ) : (
        <header className="main-header">
          {(route.pathname == "/account" ||
            isEstimatePage ||
            // route.pathname == "/estimate" ||
            route.pathname == "/self-site-survey" ||
            route.pathname == "/ai" ||
            route.pathname == "/shopnow" ||
            route.pathname === "/instant-estimate" ||
            route.pathname === "/instant-estimate-result" ||
            route.pathname === "/privacy" ||
            route.pathname === "/thank-you" ||
            route.pathname.includes("profile") ||
            landingRoutes.indexOf(
              route?.query?.slug ? route?.query?.slug.toString() : ""
            ) > -1) && (
            <nav
              id="header"
              ref={solidHeaderRef}
              className={`w-full top-0 z-40  px-4 md:px-8 xl:px-20 flex justify-between sm:items-center ${
                isClientOrg
                  ? "py-3 sm:py-4 flex-row sm:flex-row"
                  : "py-3 sm:py-5 2xl:py-6 flex-col sm:flex-row "
              } ${
                landingPagesLocation[
                  route?.query?.slug ? route?.query?.slug.toString() : ""
                ] == "suntuity"
                  ? "header-new-jersey bg-transparent"
                  : " bg-white shadow"
              } ${isSulg ? "header-solid" : ""} ${
                isEstimatePage ? "spl__headerWrapper" : ""
              }`}
            >
              <div className="flex justify-between">
                <Link href="/">
                  <a onClick={handleLogoClick}>
                    <img
                      src={`${logoUrl}`}
                      alt="Logo"
                      className={`object-contain cursor-pointer spl__brandLogo ${
                        isSuntuity ? "allwidth" : ""
                      } ${
                        isClientOrg
                          ? "customLogoWidth h-full w-full allwidth "
                          : "w-52 md:w-60"
                      } ${isEstimatePage ? "spl__brandLogo" : ""} `}
                    />
                  </a>
                </Link>
              </div>
              {/* <div > */}
              {!isSinglePageLayout && isEstimatePage && (
                <>
                  {landingPagesLocation[
                    route?.query?.slug ? route?.query?.slug.toString() : ""
                  ] == "suntuity" && (
                    <div className="flex space-x-2 xxs:space-x-3">
                      <a
                        href={`tel:${contactNumber}`}
                        className="flex space-x-1 items-center mr-2 md:mr-3"
                      >
                        <svg
                          className="h-6 w-6 text-white hover:text-white transition ease-in-out delay"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
                          />
                        </svg>
                        <span className="text-white text-sm md:text-xl ml-2">
                          {contactNumber}
                        </span>
                      </a>
                      <a href="mailto:customerservice@solarquote.com">
                        <svg
                          className="h-8 w-8 sm:w-10 sm:h-10 p-2 bg-suntuitySubSecondary text-white rounded-full hover:bg-suntuityPrimary hover:bg-opacity-80 hover:text-white transition ease-in-out delay"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                          ></path>
                        </svg>
                      </a>
                    </div>
                  )}
                  {landingPagesLocation[
                    route?.query?.slug ? route?.query?.slug.toString() : ""
                  ] != "suntuity" && (
                    <div className="flex space-x-2 xxs:space-x-3">
                      <a href={`tel:${contactNumber}`}>
                        <svg
                          className="h-8 w-8 sm:w-10 sm:h-10 p-2 bg-gray-100 text-titleColor rounded-full hover:bg-primary hover:bg-opacity-80 hover:text-white transition ease-in-out delay"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                          ></path>
                        </svg>
                      </a>
                      <a href="mailto:customerservice@solarquote.com">
                        <svg
                          className="h-8 w-8 sm:w-10 sm:h-10 p-2 bg-gray-100 text-titleColor rounded-full hover:bg-primary hover:bg-opacity-80 hover:text-white transition ease-in-out delay"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                          ></path>
                        </svg>
                      </a>
                    </div>
                  )}
                </>
              )}
              {isSinglePageLayout && !isEstimatePage && <TalkToExpert />}

              {/* </div> */}
            </nav>
          )}
        </header>
      )}
      {}

      <Modal
        preventScroll={false}
        isOpen={openOtpModal}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        onRequestClose={() => {
          closeOtpModalMethod();
        }}
        portalClassName={"EstimateDetailModalPortal"}
        htmlOpenClassName={"EstimateDetailModal__Html--open"}
        overlayClassName={
          "EstimateDetailModal__Overlay form-control-overlay otp-dialog"
        }
        ariaHideApp={false}
      >
        <OtpModal
          setAllPurchasesForContact={setAllPurchasesForContact}
          navigate={navigate}
          type="account"
          close={closeOtpModalMethod}
        ></OtpModal>
      </Modal>
      {/* OTP Modal Ends */}
      {/* PurchasesForContactModal Start */}
      <Modal
        preventScroll={false}
        isOpen={openPurchasesForContactModal}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        onRequestClose={() => {
          closePurchasesForContactModalMethod();
        }}
        portalClassName={"EstimateDetailModalPortal"}
        htmlOpenClassName={"EstimateDetailModal__Html--open"}
        overlayClassName={
          "EstimateDetailModal__Overlay form-control-overlay otp-dialog"
        }
        ariaHideApp={false}
      >
        <PurchasesForContactModal
          allPurchasesForContact={allPurchasesForContact}
          navigate={navigate}
          close={closePurchasesForContactModalMethod}
        ></PurchasesForContactModal>
      </Modal>
      {/* PurchasesForContactModal Ends */}
      {/* openCurrentPurchaseConfirmationModal Start */}
      <Modal
        preventScroll={false}
        isOpen={openCurrentPurchaseConfirmationModal}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        onRequestClose={() => setOpenCurrentPurchaseConfirmationModal(false)}
        portalClassName={"EstimateDetailModalPortal"}
        htmlOpenClassName={"EstimateDetailModal__Html--open"}
        overlayClassName={
          "EstimateDetailModal__Overlay form-control-overlay leadformModal"
        }
        ariaHideApp={false}
      >
        <CurrentPruchaseConfirmationModal
          onContinue={navigateToNewPurchase}
          onOpenCurrentPurchase={navigateToCurrentPurchase}
        />
      </Modal>
    </>
  );
};

export default Header;
